import React from "react"
import PropTypes from "prop-types"
import { useLocation } from '@reach/router';
import Header from 'gatsby-site/src/components/Header/index';

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Header/>

      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
