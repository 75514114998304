import { styled, css } from 'bumbag/styled';
import { breakpoint } from 'bumbag/src/utils/theme';
import { TopNav } from 'bumbag/TopNav';
import { Button } from 'bumbag/Button';
const { Close } = Button;

export const StyledTopNav = styled(TopNav)`
  padding-top: 10px;
  
  .menu-desktop { display: flex; }
  .menu-mobile { display: none; }

  ${breakpoint('max-tablet', css`
    .menu-desktop { display: none; }
    .menu-mobile { display: flex; }
  `)}
`;

export const StyledLogo = styled.div`
  height: 44px;
  .logo-light, .logo-dark {
    top:-6px;
    position: relative;
    svg {
      width: 180px;
      height: 44px;
    }
  }
`;

export const StyledDrawerCloseButton = styled(Close)`
  position: absolute;
  right: 21px;
  top: 14px;
`;

export const StyledDrawerModeButton = styled(Button)`
  position: absolute;
  left: 21px;
  top: 14px;
`;
