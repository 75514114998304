import React, { useState } from 'react';
import { TopNav } from 'bumbag/TopNav';
import { Button } from 'bumbag/Button';
import { Container } from 'bumbag/Container';
import { Drawer } from 'bumbag/Drawer';
import { Stack } from 'bumbag/Stack';
import LogoLight from 'shared-assets/images/svgs/logo-light.svg';
import LogoDark from 'shared-assets/images/svgs/logo-dark.svg';
import LogoLightOpen from 'shared-assets/images/svgs/logo-light-open.svg';
import LogoDarkOpen from 'shared-assets/images/svgs/logo-dark-open.svg';
import { useColorMode } from 'bumbag/ColorMode';
import { faSun } from '@fortawesome/pro-duotone-svg-icons/faSun';
import { faMoonStars } from '@fortawesome/pro-duotone-svg-icons/faMoonStars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledLogo, StyledTopNav, StyledDrawerCloseButton, StyledDrawerModeButton } from './styles';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faBars } from '@fortawesome/fontawesome-pro-regular/faBars';

// TODO: Start again with styles to make button correct height? Or just modify button styles.
// TODO: Add burger menu & drawer.

const Header = () => {
  const [hover, setHover] = useState(false);
  const { colorMode, setColorMode } = useColorMode();

  return (
    <Container breakpoint="tablet">
      <StyledTopNav>
        <TopNav.Section>
          <TopNav.Item href="https://bumbag.style" fontWeight="semibold" className="no-hover">
            <StyledLogo>
              <div className="logo-light" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <LogoLightOpen style={{display: (hover) ? 'none' : 'block'}}/>
                <LogoLight style={{display: (hover) ? 'block' : 'none'}}/>
              </div>
              <div className="logo-dark" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <LogoDark style={{display: (hover) ? 'none' : 'block'}}/>
                <LogoDarkOpen style={{display: (hover) ? 'block' : 'none'}}/>
              </div>
            </StyledLogo>
          </TopNav.Item>
        </TopNav.Section>
        <TopNav.Section className="menu-desktop">
          <TopNav.Item variant="pill" href="https://twitter.com/ankleio"><FontAwesomeIcon icon={faTwitter} style={{marginRight: 10}}/> Follow me on Twitter</TopNav.Item>
          <TopNav.Item variant="pill" href="javascript:void(0)" onClick={() => setColorMode(colorMode === 'night' ? 'default' : 'night')}>{colorMode === 'night' ? <FontAwesomeIcon icon={faMoonStars}/> : <FontAwesomeIcon icon={faSun}/>}</TopNav.Item>
        </TopNav.Section>
        <TopNav.Section className="menu-mobile">
          <Drawer.State animated>
            {drawer => (
              <>
                <Drawer.Disclosure>
                  <TopNav.Item variant="pill" href="javascript:void(0)" marginRight="sm" fontSize="400">
                    <FontAwesomeIcon icon={faBars}/>
                  </TopNav.Item>
                </Drawer.Disclosure>
                <Drawer fade slide placement="right" tabIndex={0}>
                  <StyledDrawerCloseButton onClick={drawer.hide} size="large" />

                  <TopNav.Section style={{marginTop: 14, marginLeft: 21}}>
                    <Stack spacing="minor-1">
                      <TopNav.Item variant="pill" href="javascript:void(0)" onClick={() => setColorMode(colorMode === 'night' ? 'default' : 'night')}>{colorMode === 'night' ? <FontAwesomeIcon icon={faMoonStars}/> : <FontAwesomeIcon icon={faSun}/>}</TopNav.Item>
                      <TopNav.Item variant="pill" href="https://twitter.com/ankleio"><FontAwesomeIcon icon={faTwitter} style={{marginRight: 10}}/> Follow me on Twitter</TopNav.Item>
                    </Stack>
                  </TopNav.Section>
                </Drawer>
              </>
            )}
          </Drawer.State>
        </TopNav.Section>
      </StyledTopNav>
    </Container>
  );
};

export default Header;
